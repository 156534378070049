<!-- 联系，发送内容 -->
<template>
	<div class="AcceptQuotePopup">
		<div class="AcceptQuotePopup_container">
			<div class="AcceptQuotePopup_container_title">
				<p><span>Send your inqury</span></p>
				<img class="AcceptQuotePopup_container_title_close" src="../../../assets/images/supplier/quotation/if-close.png" alt="" @click="CloseDialog('close')">
			</div>

			<div class="AcceptQuotePopup_container_form">
				<el-form :model="content" @submit="submitformSampleRequest">

					<el-form-item prop="content">
						<el-input type="textarea" :rows="10" v-model="content" placeholder="Enter content"></el-input>
					</el-form-item>
				</el-form>
				<div class="AcceptQuotePopup_container_form_btn">
					<div class="AcceptQuotePopup_container_form_btn_confirm" @click="submitformSampleRequest">Send</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup name="ContactPopup">
import { ref, defineEmits, inject, defineProps } from 'vue'
import { addQuotationMsg } from '@/api/quotationmsg'
import { ElMessage } from 'element-plus'

const props = defineProps({
  info: Object
})
const content = ref('')
const consave = ref(true)
// 提交内容
const submitformSampleRequest = async () => {
  console.log(content.value)
  if (content.value === '') {
    return false
  }
  if (!consave.value){
    return
  }
  consave.value = false
  const result = await addQuotationMsg({ content: content.value, quotationid: props.info.quotationId, requirementid: props.info.requirementId })
  if (result.code === 200) {
    consave.value = true
    ElMessage({
      message: 'Congrats, add msg success!',
      type: 'success'
    })
    CloseDialog('Send')
  }
}
console.log('info==', props.info)
// 接收父组件传递的数据
const quatationDetailId = inject('quatationDetailId')
console.log(quatationDetailId.id)

// 关闭弹窗
const emit = defineEmits(['customEvent'])
const CloseDialog = (msg) => {
  emit('customEvent', msg)
}

</script>

<style lang="scss" scoped>
@use "../../../assets/scss/elForm.scss" as *;

.AcceptQuotePopup {
	position: fixed;
	width: 100vw;
	height: 100vh;
	background: linear-gradient(180deg,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 0.6) 100%);
	line-height: 1;
	text-align: left;
	overflow-y: scroll;

	&_container {
		width: 600px;
		margin: 80px auto 0;
		background-color: #fff;
		border-radius: 8px;
		overflow: hidden;
		margin-bottom: 300px;

		&_title {
			display: flex;
			justify-content: space-between;
			padding: 30px;
			background-color: rgba(248, 248, 248, 1);

			P {
				font-size: 20px;
				margin: 0;
				font-weight: 700;
				color: rgba(24, 24, 24, 1);
			}

			&_close {
				width: 20px;
				height: 20px;
				cursor: pointer;
			}
		}

		&_form {
			width: 400px;
			margin: 50px auto;

			&_btn {
				width: 400px;
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 30px auto;

				&_not,
				&_confirm {
					font-size: 14px;
					padding: 15px 30px;
					border-radius: 4px;
					font-weight: 700;
					cursor: pointer;
				}

				&_not {
					color: rgba(255, 151, 0, 1);
					border: 1px solid rgba(255, 151, 0, 1);

					&:hover {
						color: #fff;
						background-color: rgba(255, 151, 0, 1);
					}
				}

				&_confirm {
					color: #fff;
					background-color: rgba(39, 174, 96, 1);
					margin-left: 20px;

					&:hover {
						background-color: rgba(12, 150, 69, 1);
					}
				}
			}
		}
	}
}
</style>
