<!-- 接受报价的弹窗 -->

<template>
  <div class="AcceptQuotePopup">
    <div class="AcceptQuotePopup_container">
      <div class="AcceptQuotePopup_container_title">
        <p><span>Sample Request</span></p>
        <img class="AcceptQuotePopup_container_title_close" src="../../../assets/images/supplier/quotation/if-close.png" alt="" @click="CloseDialog('close')">
      </div>

      <div class="AcceptQuotePopup_container_form">
        <!--        <el-form-->
        <!--          :model="formSampleRequest"-->
        <!--          @submit="submitformSampleRequest"-->
        <!--        >-->

        <!--          <el-form-item-->
        <!--            label="Number of samples"-->
        <!--            prop="number"-->
        <!--          >-->
        <!--            <el-input-->
        <!--              v-model="formSampleRequest.number"-->
        <!--              placeholder="Enter Number of samples"-->
        <!--              type="number"-->
        <!--            ></el-input>-->
        <!--          </el-form-item>-->
        <!--        </el-form>-->
        <div class="AcceptQuotePopup_container_form_btn">
          <div class="AcceptQuotePopup_container_form_btn_not" @click="submitformSampleRequestNot">No Sample Required</div>
          <div class="AcceptQuotePopup_container_form_btn_confirm" @click="submitformSampleOnlyRequest">I would like to have sample only</div>
          <div class="AcceptQuotePopup_container_form_btn_confirm" @click="submitformSampleRequest">I would like to order with sample</div>
        </div>
      </div>
    </div>

    <!--     提示弹窗-->
    <el-dialog v-model="centerDialogVisible" :title="dialogTitle" width="30%" lock-scroll>
      <el-form :model="formSampleRequest" @submit="submitformSampleRequest">

        <el-form-item v-show="1 > 2" label="orderType" prop="orderType">
          <el-input v-model="formSampleRequest.orderType" placeholder="Enter Number of samples"></el-input>
        </el-form-item>
        <el-form-item v-show="1 > 2" label="quotationStatus" prop="quotationStatus">
          <el-input v-model="formSampleRequest.quotationStatus" placeholder="Enter Number of samples"></el-input>
        </el-form-item>

        <el-form-item label="Number of Plain Sample" prop="number">
          <el-input v-model="formSampleRequest.number" placeholder="Enter Number of Plain Sample" type="number" onkeyup="this.value= this.value.match(/^\+?[1-9]\d*$/) ? this.value.match(/^\+?[1-9]\d*$/)[0] : ''"></el-input>
        </el-form-item>

        <el-form-item label="Number of Customized Sample" prop="number">
          <el-input v-model="formSampleRequest.customizedSampleNum" placeholder="Enter Number of Customized Sample" type="number" onkeyup="this.value= this.value.match(/^\+?[1-9]\d*$/) ? this.value.match(/^\+?[1-9]\d*$/)[0] : ''"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="centerDialogVisible = false">Cancel</el-button>
          <el-button type="primary" @click="confirm">
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>



  </div>
</template>

<script setup name="SignSampleOrderPopup">
import { reactive } from '@vue/reactivity'
import { ref, defineEmits, inject, defineProps } from 'vue'
import { ElMessageBox } from 'element-plus'
import { allowOrRefuseQuo } from '@/api/order'
import { ElMessage } from 'element-plus'
const centerDialogVisible = ref(false)
const dialogTitle = ref('')



const formSampleRequest = ref({
  number: '',
  customizedSampleNum: '',
  quotationStatus: '',
  orderType: ''
})
const props = defineProps({
  quotationId: {
    type: Number,
    required: true
  }
})
const consave = ref(true)
// 提交申请样品 quotationStatus：3=同意此报价单, orderType: 3=样品单+大货单
const submitformSampleRequest = async () => {
  console.log(formSampleRequest.value)
  if (!consave.value) {
    return
  }
  formSampleRequest.value.number = ''
  formSampleRequest.value.customizedSampleNum = ''
  formSampleRequest.value.quotationStatus = 3
  formSampleRequest.value.orderType = 3
  centerDialogVisible.value = true
  dialogTitle.value = 'Order With Sample'
  // if (formSampleRequest.value.number === '') {
  //   ElMessageBox.confirm(
  //     'Please Enter Number of Samples',
  //     'Number of Samples',
  //     {
  //       distinguishCancelAndClose: true,
  //       confirmButtonText: 'Yes',
  //       cancelButtonText: 'No',
  //       autofocus: false
  //     }
  //   )
  //     .then(() => {
  //     })
  //     .catch(() => {
  //       //  取消
  //     })
  // } else {
  //   consave.value = false
  //   const res = await allowOrRefuseQuo({ quotationId: props.quotationId, quotationStatus: 3, orderType: 3, sampleNum: formSampleRequest.value.number })
  //   console.log('res===', res)
  //   if (res.code === 200) {
  //     consave.value = true
  //     // 提交样品数量，关闭弹窗
  //     CloseDialog(3)
  //     // CloseDialog('SampleRequest')
  //   }
  // }
}

// 提交申请样品 只样品单 quotationStatus：3=同意此报价单, orderType: 1=只样品单
const submitformSampleOnlyRequest = async () => {
  console.log('formSampleRequest.value：' + formSampleRequest.value)
  if (!consave.value) {
    return
  }
  formSampleRequest.value.number = ''
  formSampleRequest.value.customizedSampleNum = ''
  formSampleRequest.value.quotationStatus = 3
  formSampleRequest.value.orderType = 1
  dialogTitle.value = 'Sample Only'
  centerDialogVisible.value = true
  // if (formSampleRequest.value.number === '') {
  //   ElMessageBox.confirm(
  //     'please Enter Number of Samples',
  //     'Number of Samples',
  //     {
  //       distinguishCancelAndClose: true,
  //       confirmButtonText: 'Yes',
  //       cancelButtonText: 'No',
  //       autofocus: false
  //     }
  //   )
  //     .then(() => {
  //     })
  //     .catch(() => {
  //       //  取消
  //     })
  // } else {
  //   consave.value = false
  //   const res = await allowOrRefuseQuo({ quotationId: props.quotationId, quotationStatus: 3, orderType: 1, sampleNum: formSampleRequest.value.number })
  //   if (res.code === 200) {
  //     consave.value = true
  //     // 提交样品数量，关闭弹窗
  //     CloseDialog(1)
  //     // CloseDialog('SampleOnlyRequest')
  //   }
  // }
}

// 接受订单不需要样品 quotationStatus：3=同意此报价单, orderType: 2=只大货单
const submitformSampleRequestNot = async () => {
  ElMessageBox.confirm(
    'Are you sure you don\'t need a sample?',
    'Sample Request',
    {
      distinguishCancelAndClose: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      autofocus: false
    }
  )
    .then(async () => {
      consave.value = false
      // 确认不需要样品单
      const res = await allowOrRefuseQuo({ quotationId: props.quotationId, quotationStatus: 3, orderType: 2, sampleNum: formSampleRequest.value.number })
      if (res.code === 200) {
        consave.value = true
        CloseDialog(2)
        // CloseDialog('noSampleRequired')
      }
    })
    .catch(() => {
      //  取消
      // CloseDialog('cancel')
    })
}

// 接收父组件传递的数据
const quatationDetailId = inject('quatationDetailId')
console.log(quatationDetailId.id)

// 关闭弹窗
const emit = defineEmits(['customEvent'])
const CloseDialog = (msg) => {
  emit('customEvent', msg)
}

// 弹框点击了确定
const confirm = async () => {
  if (!consave.value) {
    return
  }
  if (!(formSampleRequest.value.number) && !(formSampleRequest.value.customizedSampleNum)) {
    ElMessage.error('Please Enter Number')
    return
  }
  consave.value = false
  console.log(formSampleRequest.value)
  const res = await allowOrRefuseQuo({ quotationId: props.quotationId, quotationStatus: formSampleRequest.value.quotationStatus, orderType: formSampleRequest.value.orderType, sampleNum: formSampleRequest.value.number, customizedSampleNum: formSampleRequest.value.customizedSampleNum })
  if (res.code === 200) {
    consave.value = true
    centerDialogVisible.value = false
    // 提交样品数量，关闭弹窗
    CloseDialog(1)
    // CloseDialog('SampleOnlyRequest')
  }

}
</script>

<style lang="scss" scoped>
@use "../../../assets/scss/elForm.scss" as *;

.AcceptQuotePopup {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.6) 100%);
  line-height: 1;
  text-align: left;
  overflow-y: scroll;

  &_container {
    width: 890px;
    margin: 80px auto 0;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 300px;

    &_title {
      display: flex;
      justify-content: space-between;
      padding: 30px;
      background-color: rgba(248, 248, 248, 1);

      P {
        font-size: 20px;
        margin: 0;
        font-weight: 700;
        color: rgba(24, 24, 24, 1);
      }

      &_close {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }

    &_form {
      width: 790px;
      margin: 50px auto;

      &_btn {
        // width: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 30px auto;

        &_not,
        &_confirm {
          font-size: 14px;
          padding: 15px 30px;
          border-radius: 4px;
          font-weight: 700;
          cursor: pointer;
        }

        &_not {
          color: rgba(255, 151, 0, 1);
          border: 1px solid rgba(255, 151, 0, 1);

          &:hover {
            color: #fff;
            background-color: rgba(255, 151, 0, 1);
          }
        }

        &_confirm {
          color: #fff;
          background-color: rgba(39, 174, 96, 1);
          margin-left: 20px;

          &:hover {
            background-color: rgba(12, 150, 69, 1);
          }
        }
      }
    }
  }
}
</style>
